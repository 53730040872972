@use '@angular/material' as mat;
@import 'styles-variables.scss';

@include mat.core();

$custom-eos-blue-palette: (
  50: lighten($eos-blue, 52%),
  100: lighten($eos-blue, 37%),
  200: lighten($eos-blue, 26%),
  300: lighten($eos-blue, 12%),
  400: lighten($eos-blue, 6%),
  500: $eos-blue,
  600: darken($eos-blue, 6%),
  700: darken($eos-blue, 12%),
  800: darken($eos-blue, 18%),
  900: darken($eos-blue, 24%),
  A100: lighten($eos-blue, 50%),
  A200: lighten($eos-blue, 30%),
  A400: lighten($eos-blue, 10%),
  A700: darken($eos-blue, 20%),
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(0, 0, 0, 0.87),
    A200: rgba(0, 0, 0, 0.87),
    A400: rgba(0, 0, 0, 0.87),
    A700: white,
  ),
);

//$my-primary: mat.define-palette(mat.$blue-palette);
$my-primary: mat.define-palette($custom-eos-blue-palette);
$my-accent: mat.define-palette($custom-eos-blue-palette);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

.primary-text {
  color: mat.get-color-from-palette($my-primary);
}

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Angular Material provides mixins to emit styles for individual components. For example:
// @include mat.button-theme($my-theme);
// Check node_modules/@angular/material/core/theming/_all-theme.scss to see what components are available to style in this way.
// Alternatively, you can use "@include mat.all-component-themes($my-theme);" to emit styles for all components.
// This is not recommended because it will produce unnecessary CSS, but easier to use.
@include mat.all-component-themes($my-theme);

// Custom global styles for form field *using css variables*
// See: https://www.fusonic.net/en/blog/angular-material-customization
html {
  --mdc-outlined-text-field-container-shape: 10px;
  --mdc-outlined-text-field-outline-color: #cccccc;
  --mdc-outlined-text-field-hover-outline-color: #808080;
  --mdc-outlined-text-field-focus-outline-color: #3a60ff;
  --mat-form-field-container-height: 46px;
}

// This addresses the angular-material issue where the mat-form-field is not the same height
// as the mat-select. (The issue is known and considered "not a bug")
/* The .mdc-select__anchor is the clickable area on a mat-select */
.mat-mdc-form-field.mat-form-field-appearance-outline
  .mat-mdc-select
  .mdc-select__anchor {
  height: 46px !important;
  align-items: center !important;
}
/* For inputs, if you need uniform forced height:
   The .mdc-text-field--outlined class is the wrapper around the input. */
.mat-mdc-form-field.mat-form-field-appearance-outline
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  height: 46px !important;
  align-items: center !important;
}

// When we use mat-form-field, we specify appearance="outline" and that ends up having a transparent background.
// We don't want a transparent background.
.mat-mdc-text-field-wrapper.mdc-text-field {
  background: white;
}

// mat-elevation does not work as expected for mat-card and mat-panel.
// This workaround restores the elevation effect.
// https://github.com/angular/components/issues/26094#issuecomment-1490245622
@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);

    &.selected {
      box-shadow: 0 0 10px mat.get-color-from-palette($my-primary);
    }
  }
}
@for $i from 0 through 24 {
  .mat-mdc-menu-panel.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}

/*
  Shouldn't the theme be handling this stuff?
 */

// Rounding corners of dialogs per ux design.
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: $eos-blue !important;
  font-weight: 400 !important;
}
.mat-mdc-outlined-button:not(:disabled) {
  color: $eos-blue !important;
}

.mat-mdc-raised-button:not(:disabled) {
  background: $eos-blue !important;
}

.mat-mdc-raised-button[disabled],
.mat-mdc-raised-button.mat-mdc-button-disabled {
  background-color: $eos-blue-disabled !important;
  color: white !important;
}
